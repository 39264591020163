export const dresses = [
  {
    id: 1,
    name: "Evening Dress",
    price: "€119.99 EUR",
    discount: "€159.99 EUR",
    description: "",
    breadcrumbs: [
      { id: 1, name: "Men", href: "#" },
      { id: 2, name: "Clothing", href: "#" },
    ],
    colors: [
      {
        name: "Black",
        class: "bg-[#03060f]",
        sizes: [
          { name: "XXS", inStock: false },
          { name: "XS", inStock: true },
          { name: "S", inStock: true },
          { name: "3XL", inStock: true },
        ],
        images: [
          {
            src: "https://firebasestorage.googleapis.com/v0/b/fashion-ecommerce-2376f.appspot.com/o/47913049-7032-47e8-8ce0-f6574bb15601_dress_black_1.jpg?alt=media",
            alt: "",
          },
          {
            src: "/photos/dress_black_2.jpg",
            alt: "",
          },
        ],
      },
    ],
    highlights: [
      "Hand cut and sewn locally",
      "Dyed with our proprietary colors",
      "Pre-washed & pre-shrunk",
      "Ultra-soft 100% cotton",
    ],
    details:
      'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
  },
  {
    id: 2,
    name: "Feather Dress",
    price: "€99.99 EUR",
    description: "",
    breadcrumbs: [
      { id: 1, name: "Men", href: "#" },
      { id: 2, name: "Clothing", href: "#" },
    ],
    colors: [
      {
        name: "Red",
        class: "bg-[#62070e]",
        sizes: [
          { name: "XXS", inStock: false },
          { name: "XS", inStock: true },
          { name: "S", inStock: true },
          { name: "3XL", inStock: true },
        ],
        images: [
          {
            src: "/photos/dress_red_1.jpg",
            alt: "",
          },
        ],
      },
    ],
    highlights: [
      "Hand cut and sewn locally",
      "Dyed with our proprietary colors",
      "Pre-washed & pre-shrunk",
      "Ultra-soft 100% cotton",
    ],
    details:
      'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
  },
  {
    id: 3,
    name: "Ribbon Skirt",
    price: "€79.99 EUR",
    description: "",
    breadcrumbs: [
      { id: 1, name: "Men", href: "#" },
      { id: 2, name: "Clothing", href: "#" },
    ],
    colors: [
      {
        name: "Pink",
        class: "bg-[#801b47]",
        sizes: [
          { name: "XXS", inStock: false },
          { name: "XS", inStock: true },
          { name: "S", inStock: true },
          { name: "3XL", inStock: true },
        ],
        images: [
          {
            src: "/photos/dress_pink_1.jpg",
            alt: "",
          },
        ],
      },
    ],
    highlights: [
      "Hand cut and sewn locally",
      "Dyed with our proprietary colors",
      "Pre-washed & pre-shrunk",
      "Ultra-soft 100% cotton",
    ],
    details:
      'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
  },
  {
    id: 4,
    name: "Sparky Evening Dress",
    price: "€149.99 EUR",
    description: "",
    breadcrumbs: [
      { id: 1, name: "Men", href: "#" },
      { id: 2, name: "Clothing", href: "#" },
    ],
    colors: [
      {
        name: "Green",
        class: "bg-[#333528]",
        sizes: [
          { name: "XXS", inStock: false },
          { name: "XS", inStock: true },
          { name: "S", inStock: true },
          { name: "3XL", inStock: true },
        ],
        images: [
          {
            src: "/photos/dress_green_1.jpg",
            alt: "",
          },
        ],
      },
    ],
    highlights: [
      "Hand cut and sewn locally",
      "Dyed with our proprietary colors",
      "Pre-washed & pre-shrunk",
      "Ultra-soft 100% cotton",
    ],
    details:
      'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
  },
  {
    id: 5,
    name: "Party Dress",
    price: "€149.99 EUR",
    description: "",
    breadcrumbs: [
      { id: 1, name: "Men", href: "#" },
      { id: 2, name: "Clothing", href: "#" },
    ],
    colors: [
      {
        name: "White",
        class: "bg-[#e5e0c2]",
        sizes: [
          { name: "XXS", inStock: false },
          { name: "XS", inStock: true },
          { name: "S", inStock: true },
          { name: "3XL", inStock: true },
        ],
        images: [
          {
            src: "/photos/dress_white_1.jpg",
            alt: "",
          },
        ],
      },
    ],
    highlights: [
      "Hand cut and sewn locally",
      "Dyed with our proprietary colors",
      "Pre-washed & pre-shrunk",
      "Ultra-soft 100% cotton",
    ],
    details:
      'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
  },
  {
    id: 6,
    name: "Attractive Dress",
    price: "€79.99 EUR",
    description: "",
    breadcrumbs: [
      { id: 1, name: "Men", href: "#" },
      { id: 2, name: "Clothing", href: "#" },
    ],
    colors: [
      {
        name: "Red",
        class: "bg-[#6d0609]",
        sizes: [
          { name: "XXS", inStock: false },
          { name: "XS", inStock: true },
          { name: "S", inStock: true },
          { name: "3XL", inStock: true },
        ],
        images: [
          {
            src: "/photos/dress_red_3.jpg",
            alt: "",
          },
        ],
      },
    ],
    highlights: [
      "Hand cut and sewn locally",
      "Dyed with our proprietary colors",
      "Pre-washed & pre-shrunk",
      "Ultra-soft 100% cotton",
    ],
    details:
      'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
  },
  {
    id: 7,
    name: "Product 7",
    price: "€14.99 EUR",
    description: "",
    breadcrumbs: [
      { id: 1, name: "Men", href: "#" },
      { id: 2, name: "Clothing", href: "#" },
    ],
    colors: [
      {
        name: "Black",
        class: "bg-[#6600CC]",
        sizes: [
          { name: "XXS", inStock: false },
          { name: "XS", inStock: true },
          { name: "S", inStock: true },
          { name: "3XL", inStock: true },
        ],
        images: [
          {
            src: "/photos/dress_black_3.jpg",
            alt: "",
          },
          {
            src: "/photos/dress_black_4.jpg",
            alt: "",
          },
          {
            src: "/photos/dress_black_5.jpg",
            alt: "",
          },
        ],
      },
    ],
    highlights: [
      "Hand cut and sewn locally",
      "Dyed with our proprietary colors",
      "Pre-washed & pre-shrunk",
      "Ultra-soft 100% cotton",
    ],
    details:
      'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
  },
  {
    id: 8,
    name: "Product 8",
    price: "€14.99 EUR",
    description: "",
    breadcrumbs: [
      { id: 1, name: "Men", href: "#" },
      { id: 2, name: "Clothing", href: "#" },
    ],
    colors: [
      {
        name: "Blue",
        class: "bg-[#FF0000]",
        sizes: [
          { name: "XXS", inStock: false },
          { name: "XS", inStock: true },
          { name: "S", inStock: true },
          { name: "3XL", inStock: true },
        ],
        images: [
          {
            src: "/photos/dress_blue_1.jpg",
            alt: "",
          },
          {
            src: "/photos/dress_blue_2.jpg",
            alt: "",
          },
        ],
      },
    ],
    highlights: [
      "Hand cut and sewn locally",
      "Dyed with our proprietary colors",
      "Pre-washed & pre-shrunk",
      "Ultra-soft 100% cotton",
    ],
    details:
      'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
  },
  {
    id: 9,
    name: "Product 8",
    price: "€14.99 EUR",
    description: "",
    breadcrumbs: [
      { id: 1, name: "Men", href: "#" },
      { id: 2, name: "Clothing", href: "#" },
    ],
    colors: [
      {
        name: "Pink",
        class: "bg-[#996633]",
        sizes: [
          { name: "XXS", inStock: false },
          { name: "XS", inStock: true },
          { name: "S", inStock: true },
          { name: "3XL", inStock: true },
        ],
        images: [
          {
            src: "/photos/dress_pink_3.jpg",
            alt: "",
          },
          {
            src: "/photos/dress_pink_4.jpg",
            alt: "",
          },
          {
            src: "/photos/dress_pink_5.jpg",
            alt: "",
          },
        ],
      },
    ],
    highlights: [
      "Hand cut and sewn locally",
      "Dyed with our proprietary colors",
      "Pre-washed & pre-shrunk",
      "Ultra-soft 100% cotton",
    ],
    details:
      'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
  },
  {
    id: 10,
    name: "Product 8",
    price: "€14.99 EUR",
    description: "",
    breadcrumbs: [
      { id: 1, name: "Men", href: "#" },
      { id: 2, name: "Clothing", href: "#" },
    ],
    colors: [
      {
        name: "Green",
        class: "bg-[#FFA500]",
        sizes: [
          { name: "XXS", inStock: false },
          { name: "XS", inStock: true },
          { name: "S", inStock: true },
          { name: "3XL", inStock: true },
        ],
        images: [
          {
            src: "/photos/dress_green_3.jpg",
            alt: "",
          },
          {
            src: "/photos/dress_green_4.jpg",
            alt: "",
          },
          {
            src: "/photos/dress_green_5.jpg",
            alt: "",
          },
        ],
      },
    ],
    highlights: [
      "Hand cut and sewn locally",
      "Dyed with our proprietary colors",
      "Pre-washed & pre-shrunk",
      "Ultra-soft 100% cotton",
    ],
    details:
      'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
  },
  {
    id: 11,
    name: "Product 8",
    price: "€14.99 EUR",
    breadcrumbs: [
      { id: 1, name: "Men", href: "#" },
      { id: 2, name: "Clothing", href: "#" },
    ],
    description: "",
    colors: [
      {
        name: "Red",
        class: "bg-[#FFA500]",
        sizes: [
          { name: "XXS", inStock: false },
          { name: "XS", inStock: true },
          { name: "S", inStock: true },
          { name: "3XL", inStock: true },
        ],
        images: [
          {
            src: "/photos/dress_red_8.jpg",
            alt: "",
          },
        ],
      },
    ],
    highlights: [
      "Hand cut and sewn locally",
      "Dyed with our proprietary colors",
      "Pre-washed & pre-shrunk",
      "Ultra-soft 100% cotton",
    ],
    details:
      'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
  },
  {
    id: 12,
    name: "Product 8",
    price: "€14.99 EUR",
    description: "",
    breadcrumbs: [
      { id: 1, name: "Home", href: "#" },
      { id: 2, name: "Clothing", href: "#" },
    ],
    colors: [
      {
        name: "Red",
        class: "bg-[#FFA500]",
        sizes: [
          { name: "XXS", inStock: false },
          { name: "XS", inStock: true },
          { name: "S", inStock: true },
          { name: "3XL", inStock: true },
        ],
        images: [
          {
            src: "/photos/dress_red_5.jpg",
            alt: "",
          },
          {
            src: "/photos/dress_red_6.jpg",
            alt: "",
          },
          {
            src: "/photos/dress_red_7.jpg",
            alt: "",
          },
        ],
      },
    ],
    highlights: [
      "Hand cut and sewn locally",
      "Dyed with our proprietary colors",
      "Pre-washed & pre-shrunk",
      "Ultra-soft 100% cotton",
    ],
    details:
      'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting colors, like our upcoming "Charcoal Gray" limited release.',
  },
];
