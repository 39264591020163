import React from "react";
import AddressCard from "../AddressCard/AddressCard";
import { Button, IconButton } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import "../Cart/Cart.css";
const products = [
  {
    id: 1,
    name: "Throwback Hip Bag",
    color: "Salmon",
    price: "$90.00",
    size: "XL",
    quantity: 1,
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/shopping-cart-page-04-product-01.jpg",
    imageAlt:
      "Salmon orange fabric pouch with match zipper, gray zipper pull, and adjustable hip belt.",
  },
  {
    id: 2,
    name: "Medium Stuff Satchel",
    color: "Blue",
    price: "$32.00",
    size: "XL",
    quantity: 1,
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/shopping-cart-page-04-product-02.jpg",
    imageAlt:
      "Front of satchel with blue canvas body, black straps and handle, drawstring top, and front zipper pouch.",
  },
  // More products...
];

const OrderSummaryForm = () => {
  return (
    <div>
      <div className="p-5 shadow-lg rounded-s-md border">
        <AddressCard />
      </div>
      <div className="py-6 max-w-7xl lg:py-4">
        <div className="pb-12 border-b-[0.1rem] border-gray">
          <h2 className="mb-8 text-4xl font-bold font-bembo">Your Cart</h2>
          <table className="cartItems block w-full md:table">
            <thead>
              <tr>
                <th
                  className="columnProperties md:w-[70%] text-left"
                  colSpan="3"
                  scope="col"
                >
                  Product
                </th>
                <th className="columnProperties hidden md:block md:text-left">
                  Quantity
                </th>
                <th className="columnProperties text-right">Total</th>
              </tr>
            </thead>
            <tbody className="block md:table-row-group">
              {products.map((product) => (
                <tr
                  key={product.id}
                  className="grid grid-rows-2 grid-cols-4 md:table-row gap-6 md:gap-0"
                >
                  <td className="row-span-3 w-[10rem]">
                    <a className="">
                      <img
                        src={product.imageSrc}
                        alt={product.imageAlt}
                        className="w-20 h-20 md:w-40 md:h-40" // Example dimensions
                        loading="lazy"
                      />
                    </a>
                  </td>

                  <td
                    className="col-span-2 break-words break-all md:w-[35rem]"
                    colSpan="2"
                  >
                    <div className="text-gray-850">{product.name}</div>
                    <div className="text-gray-600">Size: {product.size}</div>
                    <div className="text-gray-600">Color: {product.color}</div>
                  </td>
                  <td className="col-span-1 col-start-2 row-start-2">
                    <div className="lg:flex items-center lg:space-x-10">
                      <div className="flex items-center space-x-2">
                        <div className="border flex items-center">
                          <IconButton>
                            <AddCircleOutlineOutlinedIcon />
                          </IconButton>
                          <span className="px-6">{product.quantity}</span>
                          <IconButton>
                            <RemoveCircleOutlineOutlinedIcon />
                          </IconButton>
                        </div>
                        <IconButton>
                          <DeleteForeverOutlinedIcon />
                        </IconButton>
                      </div>
                    </div>
                  </td>
                  <td
                    className="col-span-1 col-start-4 text-right break-words"
                    colSpan="1"
                  >
                    <div className="text-lg">{product.price}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Checkout */}
        <div className="flex justify-end py-16">
          <div className="w-full px-4 lg:w-1/3">
            <div className="flex items-end justify-end pb-4 font-helvetica">
              <h2 className="text-lg px-10 text-gray-800">Subtotal</h2>
              <p className="text-2xl text-gray-600">$100</p>
            </div>
            <div className="text-right pb-4">
              <small className="font-helvetica text-gray-600">
                FREE WORLDWIDE SHIPPING
              </small>
            </div>
            <Button
              variant="contained"
              sx={{
                width: "100%",
                py: "0.5rem",
                bgcolor: "#000",
                "&:hover": {
                  bgcolor: "#000", // Set the same background color for hover, effectively removing the hover effect.
                },
                borderRadius: 0,
              }}
            >
              Checkout
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummaryForm;
