import { RadioGroup } from "@headlessui/react";
import { Button } from "@mui/material";
import { dresses } from "../../data/dressesData";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SectionCarousel from "../Home/SectionCarousel";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ProductDetails() {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const product = dresses[productId - 1];
  const selectedColor = searchParams.get("color") || product.colors[0].name;
  const selectedColorIndex = product.colors.findIndex(
    (color) => color.name === selectedColor
  );
  const selectedSize =
    searchParams.get("size") ||
    product.colors[selectedColorIndex].sizes[0].name;

  const [mainImage, setMainImage] = useState(null);

  const handleAddToCart = () => {
    navigate("/cart");
  };

  // Reset state when the route changes
  useEffect(() => {
    setMainImage(null);
  }, [productId]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${product.name} - Women’s Fashion Store`}</title>
        <meta
          name="description"
          content={`Buy ${product.name} from our women’s fashion collection. High-quality fabric and trendy design at affordable prices.`}
        />
        <meta
          name="keywords"
          content={`women's fashion, ${product.name}, trendy dress, affordable price, fashion`}
        />
        <meta
          property="og:title"
          content={`${product.name} - Women’s Fashion Store`}
        />
        <meta
          property="og:description"
          content={`Shop ${product.name}, a trendy item from our exclusive women’s fashion collection.`}
        />
        <meta property="og:image" content={product.imageUrl} />
        <link
          rel="canonical"
          href={`https://argjentinabajraktari.com/product/${product.id}`}
        />
      </Helmet>
      <div className="bg-white pt-6 max-w-[140rem]">
        <nav aria-label="Breadcrumb">
          <ul
            role="list"
            className="flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8"
          >
            {product.breadcrumbs.map((breadcrumb) => (
              <li key={breadcrumb.id}>
                <div className="flex items-center">
                  <a
                    href={breadcrumb.href}
                    className="mr-2 text-sm font-medium text-gray-900"
                  >
                    {breadcrumb.name}
                  </a>
                  <svg
                    width={16}
                    height={20}
                    viewBox="0 0 16 20"
                    fill="currentColor"
                    aria-hidden="true"
                    className="h-5 w-4 text-gray-300"
                  >
                    <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                  </svg>
                </div>
              </li>
            ))}
            <li className="text-sm">
              <a
                href={product.id}
                aria-current="page"
                className="font-medium text-gray-500 hover:text-gray-600"
              >
                {product.name}
              </a>
            </li>
          </ul>
        </nav>

        {/* Product Details Section */}
        <section className="grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-10 px-4 pt-10">
          {/* Image gallery */}
          <div className="flex flex-col items-center">
            <div className="overflow-hidden rounden-lg max-w-[35rem] max-h-[35rem]">
              <img
                src={mainImage?.src || product.colors[0].images[0].src}
                alt={mainImage?.alt}
                className="h-full w-full object-cover object-center"
              />
            </div>

            <div className="flex flex-warp space-x-5 justify-center">
              {product.colors[selectedColorIndex].images.map((item) => (
                <div
                  key={item.src}
                  className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg max-w-[5rem] max-h-[5rem] mt-4"
                >
                  <img
                    onClick={() => setMainImage(item)}
                    src={item.src}
                    alt={item.alt}
                    className="h-full w-full object-cover object-center hover:scale-110 transition duration-500"
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Product info */}
          <div className="lg:col-span-1 maxt-auto max-w-2xl px-4 pb-16 lg:max-w-7xl lg:px-8 lg:pb-24">
            <div className="lg:col-span-2 ">
              <h1 className="text-lg lg:text-2xl font-medium font-moneta pr-10">
                {product.name}
              </h1>
            </div>

            {/* Options */}
            <div className="mt-4 lg:row-span-3 lg:mt-0">
              <h2 className="sr-only">Product information</h2>
              <div className="flex space-x-5 items-center font-moneta text-lg lg:text-xl text-gray-900 mt-6">
                <p className="font-semibold ">{product.price}</p>
                <p className="opacity-50 line-through">$400</p>
                <p className="text-red-600 font-semibold">5% Off</p>
              </div>

              <form className="mt-10">
                {/* Colors */}
                <div>
                  <h3 className="text-lg font-moneta font-semibold">Color</h3>

                  <RadioGroup
                    value={selectedColor}
                    onChange={(e) => {
                      // Update URL parameters when the selected color changes
                      setSearchParams(
                        {
                          color: e.name,
                          size: selectedSize,
                        },
                        { replace: true }
                      );
                      setMainImage(
                        product.colors[selectedColorIndex]?.images[0]
                      );
                    }}
                    className="mt-4"
                  >
                    <RadioGroup.Label className="sr-only">
                      Choose a color
                    </RadioGroup.Label>
                    <div className="flex items-center space-x-3">
                      {product.colors.map((color) => (
                        <RadioGroup.Option
                          key={color.name}
                          value={color}
                          className={({ active, checked }) =>
                            classNames(
                              color.selectedClass,
                              active ? "ring" : "",
                              !active && checked ? "ring" : "",
                              "relative -m-0.5 flex cursor-pointer items-center justify-center p-0.5 focus:outline-none"
                            )
                          }
                        >
                          <RadioGroup.Label as="span" className="sr-only">
                            {color.name}
                          </RadioGroup.Label>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              color.class,
                              "h-8 w-8 border border-black border-opacity-10"
                            )}
                          />
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>

                {/* Sizes */}
                <div className="mt-10">
                  <div className="flex items-center justify-between">
                    <h3 className="text-lg font-moneta font-semibold">Size</h3>
                    <a
                      href="/#"
                      className="text-sm font-semibold font-moneta hover:text-gray-600"
                    >
                      Size guide
                    </a>
                  </div>

                  <RadioGroup
                    value={selectedSize}
                    onChange={(value) => {
                      setSearchParams(
                        {
                          color: selectedColor,
                          size: value.name,
                        },
                        { replace: true }
                      );
                    }}
                    className="mt-4"
                  >
                    <RadioGroup.Label className="sr-only">
                      Choose a size
                    </RadioGroup.Label>
                    <div className="grid grid-cols-4 lg:grid-cols-6 md:grid-cols-6">
                      {product.colors[selectedColorIndex].sizes.map((size) => (
                        <RadioGroup.Option
                          key={size.name}
                          value={size}
                          checked={size.name}
                          disabled={!size.inStock}
                          className={({ active }) =>
                            classNames(
                              size.inStock
                                ? "cursor-pointer bg-white text-gray-900 shadow-sm"
                                : "cursor-not-allowed bg-gray-50 text-gray-200",
                              active ? "ring-black" : "",
                              "group relative flex items-center justify-center border hover:border-gray-950 py-1 px-1 text-sm font-extrabold font-moneta uppercase focus:outline-none sm:flex-1 sm:py-2"
                            )
                          }
                        >
                          {({ active, checked }) => (
                            <>
                              <RadioGroup.Label as="span">
                                {size.name}
                              </RadioGroup.Label>
                              {size.inStock ? (
                                <span
                                  className={classNames(
                                    active ? "border" : "",
                                    checked
                                      ? "border border-black"
                                      : "border-transparent",
                                    "pointer-events-none absolute -inset-px"
                                  )}
                                  aria-hidden="true"
                                />
                              ) : (
                                <span
                                  aria-hidden="true"
                                  className="pointer-events-none absolute -inset-px border-2 border-gray-200"
                                >
                                  <svg
                                    className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="none"
                                    stroke="currentColor"
                                  >
                                    <line
                                      x1={0}
                                      y1={100}
                                      x2={100}
                                      y2={0}
                                      vectorEffect="non-scaling-stroke"
                                    />
                                  </svg>
                                </span>
                              )}
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>

                <Button
                  onClick={handleAddToCart}
                  variant="contained"
                  sx={{
                    width: "100%", // Make the button take full width
                    px: ["2rem", "4rem", "6rem"],
                    py: "0.7rem",
                    bgcolor: "#000",
                    "&:hover": {
                      bgcolor: "#000", // Set the same background color for hover, effectively removing the hover effect.
                    },
                    borderRadius: 0,
                    marginTop: "16px", // Add margin to the top
                    marginBottom: "16px", // Add margin to the bottom
                  }}
                >
                  Add to Bag
                </Button>
              </form>
            </div>

            {/* Description and details */}
            <div className="lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8">
              <div>
                <h3 className="sr-only">Description</h3>

                <div className="space-y-6">
                  <p className="text-base text-gray-900 font-helvetica">
                    {product.description}
                  </p>
                </div>
              </div>

              <div className="mt-10 font-helvetica">
                <h3 className="text-xl font-semibold text-gray-900">
                  Product Details
                </h3>

                <div className="mt-4">
                  <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                    {product.highlights.map((highlight) => (
                      <li key={highlight}>
                        <span className="text-gray-600">{highlight}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="mt-10">
                <h2 className="text-xl font-semibold text-gray-900">
                  Material
                </h2>

                <div className="mt-4 space-y-6">
                  <p className="text-sm text-gray-600">{product.details}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* You may also like */}
        <section className="p-2 sm:p-10">
          <SectionCarousel data={dresses} sectionName={"You may also Like"} />
        </section>
      </div>
    </React.Fragment>
  );
}

export default ProductDetails;
