export const color = [
  "white",
  "Black",
  "Blue",
  "Brown",
  "Green",
  "Purple",
  "Red",
  "Yellow",
  "Pink",
];

export const filters = [
  {
    id: "color",
    name: "Color",
    options: [
      { value: "white", label: "White", checked: false },
      { value: "beige", label: "Beige", checked: false },
      { value: "blue", label: "Blue", checked: false },
      { value: "brown", label: "Brown", checked: false },
      { value: "green", label: "Green", checked: false },
      { value: "purple", label: "Purple", checked: false },
      { value: "yellow", label: "Yellow", checked: false },
    ],
  },
  {
    id: "size",
    name: "Size",
    options: [
      { value: "S", label: "S", checked: false },
      { value: "M", label: "M", checked: false },
      { value: "L", label: "L", checked: false },
      { value: "XL", label: "XL", checked: false },
      { value: "XXL", label: "XXL", checked: false },
    ],
  },
  {
    id: "stock",
    name: "Availability",
    options: [
      { value: "available", label: "Available" },
      { value: "pre_order", label: "Pre-Order" },
    ],
  },
];
