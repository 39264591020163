import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ProductCard.css";
const ProductCard = ({ product }) => {
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedImage, setSelectedImage] = useState(
    product.colors[0].images[0].src
  );
  const [isChangingImage, setChangingImage] = useState(false);

  const handleSelectColor = (color, image) => {
    let colorSwatch = selectedColor !== color ? color : null;
    setChangingImage(true); // Trigger the image change effect
    setSelectedColor(colorSwatch);
    setSelectedImage(image);

    // After a short delay, reset the changing image state
    setTimeout(() => setChangingImage(false), 300); // Adjust the delay time as needed
  };
  const navigate = useNavigate();
  return (
    <div className="productCard transition-all cursor-pointer box-border">
      <div
        onClick={() => navigate(`/product/${product.id}`)}
        className="max-h-[20rem] xxs:h-[13rem] sm:h-[20rem] lg:h-[15rem] xl:h-[20rem] box-border"
      >
        <img
          className="h-full w-full object-cover object-bottom"
          src={selectedImage}
          alt=""
          style={
            isChangingImage
              ? {
                  transition: "opacity 0.9s ease-in-out", // Define the transition
                  opacity: "0", // Make the image transparent during the transition
                }
              : {}
          }
        />
      </div>

      <div className="textPart bg-white box-border">
        <div>
          <p className="font-bold opacity-60">{product.name}</p>
          <p>{product.description}</p>
        </div>
        <div className="flex items-center space-x-2">
          <p className="font-semibold">{product.price}</p>
          <p className="line-through opacity-50">$1999</p>
          <p className="text-green-600 font-semibold">70% off</p>
        </div>

        {/* Color Swatches */}
        <div className="flex space-x-2 mt-2">
          {product.colors?.map((color, index) => (
            <div
              key={index}
              className={`w-6 h-6 border-2 cursor-pointer ${color.class}`}
              style={{
                boxShadow: selectedColor === color ? "0 0 0 1px black" : "none",
              }}
              title={color.name}
              onClick={() =>
                handleSelectColor(color, product.colors[index].images[0].src)
              }
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
