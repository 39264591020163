import React from "react";
import AliceCarousel from "react-alice-carousel";
import { CarouselData } from "../../data/CarouselData";
import "react-alice-carousel/lib/alice-carousel.css";

const items = CarouselData.map((item) => (
  <img className="cursor-pointer" role="presentation" src={item.image} alt="" />
));

const Carousel = () => (
  <AliceCarousel
    items={items}
    disableButtonsControls
    autoPlay
    autoPlayInterval={1500}
    autoHeight
    infinite
  />
);

export default Carousel;
