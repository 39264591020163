import React from "react";
import { useInView } from "react-intersection-observer";

const socialMediaIcons = [
  {
    text: "Facebook",
    href: "#",
    icon: (
      <svg
        aria-hidden="true"
        focusable="false"
        className="w-6 h-6"
        viewBox="0 0 18 18"
      >
        <path
          fill="currentColor"
          d="M16.42.61c.27 0 .5.1.69.28.19.2.28.42.28.7v15.44c0 .27-.1.5-.28.69a.94.94 0 01-.7.28h-4.39v-6.7h2.25l.31-2.65h-2.56v-1.7c0-.4.1-.72.28-.93.18-.2.5-.32 1-.32h1.37V3.35c-.6-.06-1.27-.1-2.01-.1-1.01 0-1.83.3-2.45.9-.62.6-.93 1.44-.93 2.53v1.97H7.04v2.65h2.24V18H.98c-.28 0-.5-.1-.7-.28a.94.94 0 01-.28-.7V1.59c0-.27.1-.5.28-.69a.94.94 0 01.7-.28h15.44z"
        ></path>
      </svg>
    ),
  },
  {
    text: "Instagram",
    href: "https://www.instagram.com/argjentinabajraktarii",
    icon: (
      <svg
        className="w-6 h-6"
        aria-hidden="true"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
        ></path>
      </svg>
    ),
  },
];

const Footer = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.4,
  });
  return (
    <div
      ref={ref}
      className={`${
        inView
          ? "animate-fade-in" // Apply animation when in view
          : "opacity-0" // Set initial opacity to 0 when not in view
      }`}
    >
      <section className="bg-white border-t-[0.1rem]">
        <div className="max-w-screen-xl px-4 py-6 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
          <nav className="flex flex-wrap justify-center">
            {[
              "How to buy",
              "Shipping Policy",
              "Privacy Policy",
              "Refund Policy",
              "Career",
            ].map((linkText) => (
              <div key={linkText} className="px-5 py-2">
                <a
                  href="#"
                  className="text-sm text-gray-500 hover:border-b hover:text-gray-900 border-black"
                >
                  {linkText}
                </a>
              </div>
            ))}
          </nav>
          <div className="flex justify-center mt-8 space-x-6">
            {socialMediaIcons.map((icon, index) => (
              <a key={index} href={icon.href} className="hover:scale-110">
                <span className="sr-only">{icon.text}</span>
                {icon.icon}
              </a>
            ))}
          </div>
          <p className="mt-8 text-base leading-6 text-center text-gray-400">
            © 2023 Argjentina Shop, Inc. All rights reserved.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Footer;
