import React from "react";

const AddressCard = () => {
  return (
    <div>
      <div className="space-y-3 font-helvetica">
        <p className="font-semibold">FirstName + LastName</p>
        <p>Address</p>
        <div className="space-y-1">
          <p className="font-semibold">Phone Number</p>
          <p>+38343123123</p>
        </div>
      </div>
    </div>
  );
};

export default AddressCard;
