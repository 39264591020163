import React from "react";
import AddressCard from "../components/AddressCard/AddressCard";
import { Divider, Grid } from "@mui/material";
import OrderTracker from "../components/Order/OrderTracker";
import { Helmet } from "react-helmet";
const OrderDetails = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Order Details - Women’s Fashion Store</title>
        <meta
          name="description"
          content="Track your order and view its details. Find out when your items will arrive with shipping updates."
        />
        <meta
          name="keywords"
          content="order details, order tracking, shipping, fashion items"
        />
        <meta
          property="og:title"
          content="Order Details - Women’s Fashion Store"
        />
        <meta
          property="og:description"
          content="View your order details and track the status of your shipment in real-time."
        />
        <meta
          property="og:image"
          content="http://example.com/images/order-details.jpg"
        />
        <link
          rel="canonical"
          href="http://example.com/account/order/:orderId"
        />
      </Helmet>
      <div className="px:5 lg:px-20">
        <div>
          <Divider className="py-7 text-xl">Delivery Address</Divider>
          <AddressCard />
        </div>
        <div className="py-20">
          <OrderTracker activeStep={3} />
        </div>
        <Grid className="space-y-5" container>
          {[1, 1, 1, 1, 1].map((item) => (
            <Grid
              item
              container
              className="shadow-xl rounded-md p-5 border"
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Grid item xs={6}>
                <div className="flex items-center space-x-4">
                  <img
                    className="w-[5rem] h-[5rem] object-cover object-top"
                    src="https://tailwindui.com/img/ecommerce-images/shopping-cart-page-04-product-01.jpg"
                    alt=""
                  />
                  <div className="space-y-2 ml-5">
                    <p className="font-semibold">Product Name</p>
                    <p className="space-x-5 opacity-50 text-xs font-semibold">
                      <span>Color: Black</span>
                      <span>Size: XL</span>
                    </p>
                    <p>Seller: Argjentina</p>
                    <p>1099EUR</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default OrderDetails;
