import axios from "axios";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const G_JWT = localStorage.getItem("jwt");

export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${G_JWT}`,
    "Content-Type": "application/json",
  },
});
