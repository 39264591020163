export const CarouselData = [
  {
    image:
      "https://image.s5a.com/is/image/saks/101023_MHP_WA_HERO_WINTER_01?scl=1&qlt=84&fmt=jpg",
    path: "/women/clothing/1",
  },
  {
    image:
      "https://image.s5a.com/is/image/saks/101023_MHP_WA_HERO_ZIMMERMAN_02?scl=1&qlt=84&fmt=jpg",
    path: "/women/clothing/2",
  },
  {
    image:
      "https://image.s5a.com/is/image/saks/101023_MHP_WA_HERO_ETRO_03?scl=1&qlt=84&fmt=jpg",
    path: "/women/clothing/3",
  },
  {
    image:
      "https://amq-mcq.dam.kering.com/m/13588da692e94ad3/Large-DEKSTOP-2.jpg",
    path: "/women/clothing/4",
  },
];
