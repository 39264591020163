import { Grid } from "@mui/material";
import React from "react";
import OrderCard from "./OrderCard";
import { Helmet } from "react-helmet";

const orderStatus = [
  { label: "On the Way", value: "on_the_way" },
  { label: "Returned", value: "returned" },
  { label: "Delivered", value: "delivered" },
  { label: "Cancelled", value: "cancelled" },
];
const Order = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Your Orders - Women’s Fashion Store</title>
        <meta
          name="description"
          content="View your past orders, track shipments, and reorder your favorite women’s fashion items with ease."
        />
        <meta
          name="keywords"
          content="order history, women’s fashion, reorder, shipping tracker, order management"
        />
        <meta
          property="og:title"
          content="Your Orders - Women’s Fashion Store"
        />
        <meta
          property="og:description"
          content="Check the status of your past orders and easily reorder your favorite items from our women’s fashion collection."
        />
        <meta
          property="og:image"
          content="http://example.com/images/orders-icon.jpg"
        />
        <link rel="canonical" href="http://example.com/account/orders" />
      </Helmet>
      <div className="px-5 lg:px-20">
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid item xs={2.5}>
            <div className="h-auto shadow-lg bg-white p-5 sticky top-5">
              <h1 className="font-bold text-lg">Filter</h1>
              <div className="space-y-4 mt-10">
                <h1 className="font-semibold">Order Status</h1>
                {orderStatus.map((option) => (
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      defaultValue={option.value}
                      className="h-4 w-4 border-gray-300 focus:ring-black"
                    />
                    <label
                      className="ml-3 text-sm text-gray-600"
                      htmlFor={option.value}
                    >
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item xs={9}>
            <div className="space-y-4">
              {[1, 1, 1, 1].map((item) => (
                <OrderCard />
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default Order;
