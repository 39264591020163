import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import DeliveryAddresForm from "../components/Checkout/DeliveryAddresForm";
import OrderSummaryForm from "../components/Checkout/OrderSummaryForm";
import { Helmet } from "react-helmet";

const steps = ["Log In", "Delivery Address", "Order Summary", "Payment"];

function Checkout() {
  const [activeStep, setActiveStep] = React.useState(0);
  const location = useLocation();
  const querySearch = new URLSearchParams(location.search);
  const step = querySearch.get("step");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className="px-10 lg:px-20">
      <Helmet>
        <title>Checkout - Women’s Fashion Store</title>
        <meta
          name="description"
          content="Securely checkout your order from our exclusive women's fashion collection. Safe payment options and fast shipping."
        />
        <meta
          name="keywords"
          content="checkout, payment, shopping, fashion store, online shopping"
        />
        <meta property="og:title" content="Checkout - Women’s Fashion Store" />
        <meta
          property="og:description"
          content="Complete your purchase with a secure checkout process. Fast shipping and great deals on women's fashion."
        />
        <meta
          property="og:image"
          content="http://example.com/images/checkout-icon.jpg"
        />
        <link rel="canonical" href="http://example.com/checkout" />
      </Helmet>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={step}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />

              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
            <div className="mt-10">
              {step === 2 ? <DeliveryAddresForm /> : <OrderSummaryForm />}
            </div>
          </React.Fragment>
        )}
      </Box>
    </div>
  );
}
export default Checkout;
